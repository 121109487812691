<!--
 * @Author: wh
 * @Date: 2020-07-28 20:24:40
 * @LastEditTime: 2022-03-04 11:47:27
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \JiYun_Web_test\src\views\salesBusiness\contractManage\contractMakeup\dayContract.vue
-->
<template>
    <div class="dayContract" id="dayContract">
        <div class="table-container">
            <div class="table-con">
                <div class="table-header-wrapper">
                    <table class="custom-table custom-table-head">
                        <thead>
                            <!-- <tr>
                            <th colspan="15" class="table-tit">
                                XXXXXX 合同数据补录表
                            </th>
                        </tr> -->
                            <tr>
                                <th class="column-tit" style="width:1rem;">
                                    <div>
                                        客户名称：
                                    </div>
                                </th>
                                <td colspan="2">
                                    <div class="td_div">
                                        {{ contractSupplementMonth.client_name }}
                                    </div>
                                </td>
                                <th class="column-tit" style="width:1rem;">
                                    <div>
                                        合同编号：
                                    </div>
                                </th>
                                <td colspan="2">
                                    <div class="td_div">
                                        {{ contractSupplementMonth.contract_number }}
                                    </div>
                                </td>
                                <th class="column-tit" style="width:1rem;">
                                    <div>
                                        工程名称：
                                    </div>
                                </th>
                                <td colspan="2">
                                    <div class="td_div">
                                        {{ contractSupplementMonth.engineering_name }}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="column-tit">
                                    <div>
                                        场站：
                                    </div>
                                </th>
                                <td colspan="2" class="select_box">
                                    <el-select
                                        v-model="contractSupplementMonth.supply_site_name"
                                        placeholder="请选择"
                                        @change="supplySiteChange"
                                    >
                                        <el-option
                                            v-for="k in supplySites"
                                            :key="k.code"
                                            :label="k.name"
                                            :value="k.name"
                                        >
                                        </el-option>
                                    </el-select>
                                </td>
                                <th class="column-tit">
                                    <div>
                                        补录时间：
                                    </div>
                                </th>
                                <td colspan="2">
                                    <el-date-picker
                                        v-model="contractSupplementMonth.supplement_day"
                                        type="date"
                                        value-format="yyyy-MM-dd"
                                        @change="changeDate"
                                        class="date_box"
                                    >
                                    </el-date-picker>
                                </td>
                                <th class="column-tit">
                                    <div>
                                        录入人：
                                    </div>
                                </th>
                                <td colspan="2" class="readonly_item">
                                    <el-input v-model="contractSupplementMonth.creator" readonly></el-input>
                                </td>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div class="table-header-wrapper">
                    <div class="head_title_container">
                        <div>
                            <div class="side1"></div>
                            <div class="side2"></div>
                            <span class="title">本车原材料消耗</span>
                            <div class="side2"></div>
                            <div class="side1"></div>
                        </div>
                        <p class="title_tip">
                            单位：方，人民币元，元/方
                        </p>
                    </div>
                    <table class="custom-table custom-table-main">
                        <thead>
                            <!-- <tr>
                                <th colspan="7">
                                    补录每日实发数据情况如下：
                                </th>
                                <th colspan="8">
                                    单位：方，人民币元，元/方
                                </th>
                            </tr> -->
                            <tr>
                                <th class="column-tit" rowspan="2" style="width:1rem">
                                    <div>
                                        产品标号
                                    </div>
                                </th>
                                <th class="column-tit" rowspan="2" style="width:.9rem">
                                    <div>
                                        附加型号
                                    </div>
                                </th>
                                <th class="column-tit" rowspan="2" style="width:.9rem">
                                    <div>
                                        是否泵送
                                    </div>
                                </th>
                                <th class="column-tit text-center" colspan="3">
                                    <div>
                                        砼
                                    </div>
                                </th>
                                <th class="column-tit text-center" colspan="4">
                                    <div>
                                        泵送
                                    </div>
                                </th>
                                <th class="column-tit text-center" colspan="3">
                                    <div>
                                        运费
                                    </div>
                                </th>
                                <th class="column-tit text-center" rowspan="2" style="width:.91rem">
                                    <div>
                                        小计
                                    </div>
                                </th>
                                <th class="column-tit text-center" rowspan="2" style="width:1.16rem">
                                    <div>
                                        操作列
                                    </div>
                                </th>
                            </tr>
                            <tr>
                                <th class="column-tit" style="width:.9rem">
                                    <div>
                                        方量
                                    </div>
                                </th>
                                <th class="column-tit" style="width:.9rem">
                                    <div>
                                        单价
                                    </div>
                                </th>
                                <th class="column-tit" style="width:.9rem">
                                    <div>
                                        砼金额
                                    </div>
                                </th>
                                <th class="column-tit" style="width:.9rem">
                                    <div>
                                        泵送方量
                                    </div>
                                </th>
                                <th class="column-tit" style="width:1rem">
                                    <div>
                                        单价
                                    </div>
                                </th>
                                <th class="column-tit" style="width:.9rem">
                                    <div>
                                        泵送金额
                                    </div>
                                </th>
                                <th class="column-tit" style="width:1.2rem">
                                    <div>
                                        泵送补方金额
                                    </div>
                                </th>
                                <th class="column-tit" style="width:.9rem">
                                    <div>
                                        运费补方
                                    </div>
                                </th>
                                <th class="column-tit" style="width:.9rem">
                                    <div>
                                        单价
                                    </div>
                                </th>
                                <th class="column-tit" style="width:.9rem">
                                    <div>
                                        补运费
                                    </div>
                                </th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div class="table-body-wrapper">
                    <table class="custom-table">
                        <tbody>
                            <template v-for="(item, index) in supplementDetailedMonthList">
                                <tr :key="index">
                                    <td style="width:1rem">
                                        <el-input
                                            v-model="item.product_mark_num"
                                            placeholder="请选择"
                                            @focus="choseStrength(index)"
                                        ></el-input>
                                    </td>
                                    <td style="width:.9rem">
                                        <el-input
                                            v-model="item.additional_model"
                                            placeholder="请选择"
                                            @focus="choseAdditional(index)"
                                        ></el-input>
                                    </td>
                                    <td style="width:.9rem">
                                        <el-switch
                                            v-model="item.is_pump"
                                            @change="val => switchChange(val, item, index)"
                                        >
                                        </el-switch>
                                    </td>
                                    <td style="width:.9rem">
                                        <el-input
                                            v-model="item.concrete_volume"
                                            placeholder="请输入"
                                            @blur="computedSum(item,index,'concrete')"
                                        ></el-input>
                                    </td>
                                    <td style="width:.9rem">
                                        <el-input
                                            v-model="item.concrete_unit_price"
                                            placeholder="请输入"
                                            @blur="computedSum(item,index,'concrete')"
                                        ></el-input>
                                    </td>
                                    <td style="width:.9rem">
                                        <el-input
                                            v-model="item.concrete_amount"
                                            placeholder="请输入"
                                            @blur="computedSum(item,index,'sum')"
                                        ></el-input>
                                    </td>
                                    <td style="width:.9rem">
                                        <el-input
                                            v-model="item.pump_volume"
                                            :disabled="!item.is_pump"
                                            placeholder="请输入"
                                            @blur="computedSum(item,index,'pump')"
                                        ></el-input>
                                    </td>
                                    <td style="width:1rem">
                                        <el-input
                                            v-model="item.pump_unit_price"
                                            :disabled="!item.is_pump"
                                            placeholder="请输入"
                                            @blur="computedSum(item,index,'pump')"
                                        ></el-input>
                                    </td>
                                    <td style="width:.9rem">
                                        <el-input
                                            v-model="item.pump_amount"
                                            :disabled="!item.is_pump"
                                            placeholder="请输入"
                                            @blur="computedSum(item,index,'sum')"
                                        ></el-input>
                                    </td>
                                    <td style="width:1.2rem">
                                        <el-input
                                            v-model="item.pump_add_amount"
                                            :disabled="!item.is_pump"
                                            placeholder="请输入"
                                            @blur="computedSum(item,index,'pump_add')"
                                        ></el-input>
                                    </td>
                                    <td style="width:.9rem">
                                        <el-input
                                            v-model="item.transport_volume"
                                            placeholder="请输入"
                                            @blur="computedSum(item,index,'transport')"
                                        ></el-input>
                                    </td>
                                    <td style="width:.9rem">
                                        <el-input
                                            v-model="item.transport_unit_price"
                                            placeholder="请输入"
                                            @blur="computedSum(item,index,'transport')"
                                        ></el-input>
                                    </td>
                                    <td style="width:.9rem">
                                        <el-input
                                            v-model="item.transport_amount"
                                            @blur="computedSum(item,index,'sum')"
                                            placeholder="请输入"
                                        ></el-input>
                                    <!-- {{ item.transport_amount }} -->
                                    </td>
                                    <td style="width:.91rem">
                                        {{ item.subtotal }}
                                    </td>
                                    <td class="text-center" style="width:1.15rem">
                                        <!-- <el-button
                                        type="text"
                                        @click="newData"
                                    >
                                        新增
                                    </el-button> -->
                                        <el-button
                                            type="danger"
                                            plain
                                            size="mini"
                                            icon="el-icon-delete"
                                            @click="delData(index, $event)"
                                            @mouseleave.native="mouseLeave($event)"
                                        >
                                        </el-button>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
                <div class="table-foot-wrapper">
                    <table class="custom-table">
                        <tfoot>
                            <tr>
                                <td style="width:1rem">
                                    合计
                                </td>
                                <td style="width:.9rem">
                                </td>
                                <td style="width:.9rem">
                                </td>
                                <td style="width:.9rem">
                                    {{ contractSupplementMonth.concrete_total_volume }}
                                </td>
                                <td style="width:.9rem"></td>
                                <td style="width:.9rem">
                                    {{ contractSupplementMonth.concrete_total_amount }}
                                </td>
                                <td style="width:.9rem">
                                    {{ contractSupplementMonth.pump_total_volume }}
                                </td>
                                <td style="width:1rem">
                                </td>
                                <td style="width:.9rem">
                                    {{ contractSupplementMonth.pump_total_amount }}
                                </td>
                                <td style="width:1.2rem">
                                    {{ contractSupplementMonth.pump_add_total_amount }}
                                </td>
                                <td style="width:.9rem">
                                    {{ contractSupplementMonth.transport_total_volume }}
                                </td>
                                <td style="width:.9rem"></td>
                                <td style="width:.9rem">
                                    {{ contractSupplementMonth.transport_total_amount }}
                                </td>
                                <td style="width:.91rem">
                                    {{ contractSupplementMonth.total_subtotal }}
                                </td>
                                <td class="text-center" style="width:1.15rem">
                                    <el-button
                                        type="primary"
                                        plain
                                        size="mini"
                                        class="add_btn"
                                        @click="newData"
                                    >
                                        新增一行
                                    </el-button>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <footer>
                <el-button
                    type="primary"
                    plain
                    size="mini"
                    @click="handleClose"
                >
                    关闭
                </el-button>
                <el-button
                    type="primary"
                    plain
                    size="mini"
                    @click="handleSave"
                >
                    保存
                </el-button>
            </footer>
        </div>
    </div>
</template>

<script>
import store from '@/store';
export default {
    components: {},
    props: {
        extr: {
            type: Object,
        },
    },
    data() {
        return {
            // 主表信息
            contractSupplementMonth: {},
            // 拓展表
            supplementDetailedMonthList: [],
            // 场站
            supplySites: [],
            // 选中行
            selectIndex: 0,
            // 新增一行空字段集合
            newTempJson: {
                sdmid: '',
                contract_supplement_month_id: '',
                product_mark_num: '',
                product_mark_code: '',
                additional_model_code: '',
                additional_model: '',
                is_pump: true,
                concrete_volume: null,
                concrete_unit_price: null,
                concrete_amount: null,
                pump_volume: null,
                pump_unit_price: null,
                pump_amount: null,
                transport_volume: null,
                transport_unit_price: null,
                transport_amount: null,
                subtotal: null,
                sort_num: 10000,
            },
            // 合同编号
            contract_number: '',
            // 租户id
            tenant_id: '',
            // 操作类型
            handleType: '',
            // 日期范围
            rangeDate: '',
        };
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {
        this.getStationList();
        this.handleType = this.extr.handleType;
        if (this.handleType === 'new') {
            this.initNew();
        } else if (this.handleType === 'modify') {
            this.initModify(this.extr.tcsid);
        }
        // document.getElementById('dayContract').getElementsByTagName('img')[0].setAttribute('class','box');
        // console.log(document.getElementById('dayContract').getElementsByTagName('img')[0]);
    },
    methods: {
        switchChange(val, item, i) {
            if (!val) {
                this.supplementDetailedMonthList[i].pump_volume = '';
                this.supplementDetailedMonthList[i].pump_unit_price = '';
                this.supplementDetailedMonthList[i].pump_amount = '';
                this.supplementDetailedMonthList[i].pump_add_amount = '';
                this.computedSum(item, i, 'pump');
                this.computedSum(item, i, 'sum');
                this.computedSum(item, i, 'pump_add');
            }
        },
        // 初始化新增
        async initNew() {
            const QueryString = this.extr.QueryString;
            const arr = QueryString.split('&');
            this.contract_number = arr[0].split('=')[1];
            this.tenant_id = arr[1].split('=')[1];
            const res = await this.getContractApi();
            if (res) {
                this.contractSupplementMonth.contract_number = res.contract_number;
                this.contractSupplementMonth.client_name = res.client_name;
                this.contractSupplementMonth.engineering_name = res.engineering_name;
                this.contractSupplementMonth.tenant_id = this.tenant_id;
                this.contractSupplementMonth.supply_site = res.supply_site;
                this.contractSupplementMonth.supply_site_name = res.supply_site_name;
                this.$forceUpdate();
            }
            this.contractSupplementMonth.creator = this.$takeTokenParameters('Name');
            this.newData();
        },
        // 初始化修改
        async initModify(tcsid) {
            const res = await this.getModifyApi(tcsid);
            if (res) {
                this.contractSupplementMonth = res.contractSupplementDay;
                this.supplementDetailedMonthList = res.supplementDetailedDayList;
                // this.rangeDate = res.contractSupplementDay.supplement_day;
                // this.rangeDate = res.contractSupplementMonth.supplement_year + '-' + res.contractSupplementMonth.supplement_month;
            }
        },
        // 保存
        async handleSave() {
            if (!this.filterTempData()) {
                return this.$message.error('产品标号不能为空');
            }
            if (this.handleType === 'new') {
                const res = await this.addContractMonthApi();
                if (res) {
                    this.$message.success('保存成功！');
                    this.contractSupplementMonth.tcsid = res;
                    this.handleType = 'modify';
                    this.$parent.hide();
                    store.state.currentOpenList.forEach(item => {
                        item.tableObj.updateTable();
                    });
                }
            } else {
                const res = await this.modifyContractMonthApi();
                if (res) {
                    this.$message.success('修改成功！');
                    this.$parent.hide();
                    store.state.currentOpenList.forEach(item => {
                        item.tableObj.updateTable();
                    });
                }
            }
        },
        // 过滤空数据
        filterTempData() {
            let flag = true;
            this.supplementDetailedMonthList.map(item => {
                if (!item.product_mark_code) {
                    flag = false;
                }
            });
            return flag;
        },
        // 取消
        handleClose() {
            this.$parent.hide();
        },
        // 日期选择
        changeDate() {
            // if (this.rangeDate) {
            //     this.contractSupplementMonth.supplement_day = this.rangeDate;
            // }
        },
        // 自动计算合计
        computedSum(data, index, type) {
            this.selectIndex = index;
            if (type === 'concrete') {
                data.concrete_amount = (Number(data.concrete_volume) * Number(data.concrete_unit_price)).toFixed(2);
                this.computedRowTotal();
                this.computedTotal();
            }
            if (type === 'pump') {
                data.pump_amount = (Number(data.pump_volume) * Number(data.pump_unit_price)).toFixed(2);
                this.computedRowTotal();
                this.computedTotal();
            }
            if (type === 'transport') {
                data.transport_amount = (Number(data.transport_volume) * Number(data.transport_unit_price)).toFixed(2);
                this.computedRowTotal();
                this.computedTotal();
            }
            if (type === 'pump_add') {
                this.computedRowTotal();
                this.computedTotal();
            }
            if (type === 'sum') {
                this.computedRowTotal();
                this.computedTotal();
            }
        },
        // 更新行合计
        computedRowTotal() {
            const row = this.supplementDetailedMonthList[this.selectIndex];
            let total = 0;
            row.subtotal = 0;
            total = (Number(total) + Number(row.concrete_amount)).toFixed(2);
            total = (Number(total) + Number(row.pump_amount)).toFixed(2);
            total = (Number(total) + Number(row.pump_add_amount || 0)).toFixed(2);
            total = (Number(total) + Number(row.transport_amount)).toFixed(2);
            row.subtotal = total;
        },
        // 更新全部合计
        computedTotal() {
            const rows = this.supplementDetailedMonthList;
            const mainInfo = this.contractSupplementMonth;
            mainInfo.concrete_total_volume = 0;
            mainInfo.concrete_total_amount = 0;
            mainInfo.pump_total_volume = 0;
            mainInfo.pump_total_amount = 0;
            mainInfo.pump_add_total_amount = 0;
            mainInfo.transport_total_volume = 0;
            mainInfo.transport_total_amount = 0;
            mainInfo.total_subtotal = 0;
            rows.map(item => {
                mainInfo.concrete_total_volume = (Number(mainInfo.concrete_total_volume) + Number(item.concrete_volume)).toFixed(2);

                mainInfo.concrete_total_amount = (Number(mainInfo.concrete_total_amount) + Number(item.concrete_amount)).toFixed(2);
                mainInfo.pump_total_volume = (Number(mainInfo.pump_total_volume) + Number(item.pump_volume)).toFixed(2);
                mainInfo.pump_total_amount = (Number(mainInfo.pump_total_amount) + Number(item.pump_amount)).toFixed(2);
                mainInfo.pump_add_total_amount = (Number(mainInfo.pump_add_total_amount) + Number(item.pump_add_amount || 0)).toFixed(2);
                mainInfo.transport_total_volume = (Number(mainInfo.transport_total_volume) + Number(item.transport_volume)).toFixed(2);
                mainInfo.transport_total_amount = (Number(mainInfo.transport_total_amount) + Number(item.transport_amount)).toFixed(2);
                mainInfo.total_subtotal = (Number(mainInfo.total_subtotal) + Number(item.subtotal)).toFixed(2);
            });

        },

        // 新增一行
        newData() {
            const jsonObj = JSON.parse(JSON.stringify(this.newTempJson));
            this.supplementDetailedMonthList.push(jsonObj);
        },
        // 删除一行
        delData(index, ev) {
            this.supplementDetailedMonthList.splice(index, 1);
            this.computedTotal();
            ev.target.blur();
        },
        // 鼠标移出取消高亮
        mouseLeave(ev) {
            ev.target.blur();
        },
        // 选择强度等级
        choseStrength(index) {
            const contract_number = this.contract_number || this.contractSupplementMonth.contract_number;
            this.selectIndex = index;
            this.$toast({
                title: true,
                text: '选择强度等级',
                type: 'eject',
                width: '10rem',
                height: '80%',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: 'htbl_qddj',
                        QueryString: 'contract_number=' + contract_number,
                    },
                    sureCallback: this.selectStrengthCallback,
                },
            });
        },
        // 选择强度等级完成回调
        selectStrengthCallback(data) {
            this.supplementDetailedMonthList.splice(this.selectIndex, 1);
            data.map(item => {
                const jsonObj = JSON.parse(JSON.stringify(this.newTempJson));
                jsonObj.product_mark_num = item.product_mark_num;
                jsonObj.product_mark_code = item.product_mark_code;
                jsonObj.sort_num = item.sort_num;
                jsonObj.concrete_unit_price = item.product_mark_price;
                this.supplementDetailedMonthList.push(jsonObj);
            });
            this.sortMonthList();
        },
        // 数据排序
        sortMonthList() {
            this.supplementDetailedMonthList.sort((a, b) => {
                return a.sort_num - b.sort_num;
            });
        },
        // 选择附加型号
        choseAdditional(index) {
            const contract_number = this.contract_number || this.contractSupplementMonth.contract_number;
            this.selectIndex = index;
            const product_mark_code = this.supplementDetailedMonthList[this.selectIndex].product_mark_code;
            if (product_mark_code) {
                this.$toast({
                    title: true,
                    text: '选择附加型号',
                    type: 'eject',
                    width: '10rem',
                    height: '80%',
                    t_url: 'tableList/index',
                    extr: {
                        code: {
                            TableCode: 'htbl_fjxh',
                            QueryString: 'contract_number=' + contract_number + '&fjxh=' + product_mark_code,
                        },
                        sureCallback: this.selectAdditionalCallback,
                    },
                });
            } else {
                this.$message.warning('请先选择强度等级！');
            }
        },
        // 选择附加型号回调
        selectAdditionalCallback(data) {
            let additional_model = '';
            let additional_model_code = '';
            let new_additional_model_price = this.supplementDetailedMonthList[this.selectIndex].concrete_unit_price;
            data.map(item => {
                additional_model += item.additional_model + ',';
                additional_model_code += item.additional_model_code + ',';
                new_additional_model_price = Number(new_additional_model_price) + Number(item.additional_model_price);
            });
            additional_model = additional_model.substr(0, additional_model.length - 1);
            additional_model_code = additional_model_code.substr(0, additional_model_code.length - 1);

            this.supplementDetailedMonthList[this.selectIndex].additional_model = additional_model;
            this.supplementDetailedMonthList[this.selectIndex].additional_model_code = additional_model_code;
            this.supplementDetailedMonthList[this.selectIndex].concrete_unit_price = new_additional_model_price;
        },
        // 获取供货站点下拉list
        async getStationList() {
            const res = await this.getStationListApi();
            if (res) {
                this.supplySites = res.filter(item => item.is_enabled === true);
            }
        },
        // 供应站点选中事件
        supplySiteChange(value) {
            const data = this.supplySites.filter(item => item.name === value);
            this.contractSupplementMonth.supply_site_name = data[0].name;
            this.contractSupplementMonth.supply_site = data[0].station_id;
            this.$forceUpdate();
        },
        // 获取场站
        getStationListApi() {
            const queryStr = '?companyId=' + this.$takeTokenParameters('CompanyId') + '&branchId=' + this.$takeTokenParameters('BranchId');
            return this.$axios.$get('/interfaceApi/baseinfo/stationmanger/get_station_list' + queryStr, { defEx: true });
        },
        // 拉取合同详情
        getContractApi() {
            return this.$axios.$get('/interfaceApi/sale/contract_manager/get_contract_supplement/' + this.contract_number, { defEx: true });
        },
        // 添加数据补录(月)数据
        addContractMonthApi() {
            const saveData = {
                contractSupplementDay: this.contractSupplementMonth,
                supplementDetailedDayList: this.supplementDetailedMonthList,
            };
            return this.$axios.$post('/interfaceApi/report/contract_supplement/add_contract_supplement_day', saveData, { defEx: true });
        },
        // 修改数据补录(月)数据
        modifyContractMonthApi() {
            const saveData = {
                contractSupplementDay: this.contractSupplementMonth,
                supplementDetailedDayList: this.supplementDetailedMonthList,
            };
            return this.$axios.$put('/interfaceApi/report/contract_supplement/modify_contract_supplement_day', saveData, { defEx: true });
        },
        // 拉取修改数据
        getModifyApi(id) {
            return this.$axios.$get('/interfaceApi/report/contract_supplement/get_contract_supplement_day?id=' + id, { defEx: true });
        },
    },
};
</script>
<style lang="stylus">
.dayContract
    height 100%
    overflow-x hidden
    .add_btn
        width 1.08rem
        font-size .16rem
        display flex
        justify-content center
        align-items center
    .head_title_container
            width: calc(100% + .1rem);
            margin-top .1rem
        .title_tip
            position absolute
            right .2rem
            padding-top .04rem
            font-weight normal
            font-family none
            color #000
    .table-container
        height 100%
        padding .2rem
        .table-con
            height calc(100% - 1.1rem)
            .table-body-wrapper
                max-height calc(100% - 2.8rem)
                overflow-y auto
            .table-foot-wrapper
                padding-right .1rem
            .custom-table
                border 1px solid #D7D7D7
                border-spacing 0px
                border-collapse collapse
                width 13.9rem
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button
                    -webkit-appearance none !important
                // .pump-select
                    // .el-select
                    //     width 100%
                    //     input
                    //         border 1px solid #DCDFE6 !important
                    //         height .32rem
                    //         line-height .32rem
                    //     .el-input__icon
                    //         line-height .32rem!important
                th
                    text-align right
                    font-weight normal
                .el-select
                    width 100%
                .text-center
                    text-align center
                td,th
                    border 1px solid #D7D7D7
                    border-collapse collapse
                    height .42rem
                    line-height .42rem
                    font-size .16rem
                    input
                        width 100%
                        height 100%
                        font-size 0.16rem
                        height 0.46rem
                        line-height 0.46rem
                        // border none
                    // .el-input__inner
                    //     border none
                    .danger
                        span
                            color #E60000
                td
                    padding .02rem
                    .el-input__inner
                        padding 0
                .date_box
                    width 100%
                    display flex
                    align-items center
                    .el-input__inner
                        padding-left .4rem
                .column-tit
                    background #fff
                    // color #022782
                    padding .02rem
                    div
                        width 100%
                        height 100%
                        display flex
                        justify-content center
                        align-items center
                        background #F8F8F8 !important
                .table-tit
                    background #edf0f5
                    text-align center
                    font-size .16rem
                    font-weight bold
                    color #022782
            .custom-table-main
                th
                    text-align center !important
        .add-btn
            width 12rem
            border 1px dashed #D7D7D7
            height .42rem
            margin-top .1rem
            text-align center
            line-height .4rem
            cursor pointer
            background #FCFCFC
            position: absolute;
            bottom .85rem
            span
                font-size .16rem
                color #979EA7
                margin-right .05rem
        .text-center
            text-align center
        footer
            text-align: center;
            position: absolute;
            width: 13.9rem
            bottom: .2rem;
            button
                width  2.6rem
                // height 0.5rem
                // line-height 0.5rem
                font 0.2rem '微软雅黑'
                margin 0 0.15rem
                // border none
                // cursor pointer
                // &.save
                //     color #fff
                //     background #1577d2
                // &.Close
                //     color #1577d2
                //     background #fff
                //     border 1px solid #1577d2
.custom-table-head
    td
        padding .02rem !important
        .td_div
            border 1px solid #D7D7D7
            padding-left .08rem
            background #F8F8F8
.select_box
    .el-input__inner
        padding-left .08rem !important
.readonly_item
    padding-left 0 !important
    .el-input__inner
        padding-left .08rem !important
        background #F8F8F8 !important
</style>
